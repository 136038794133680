/* You can add global styles to this file, and also import other style files */
@import "./node_modules/@thg-procure-team/procure-common-ui/resources/scss/icons";

body {
  display: none;
}

th.w-60 {
  width: 60px;
}

.tooltip-large {
  min-width: 300px;
  text-align: center;
}

.delivery-bookings-table {
  th {
    padding: .5em !important;
  }

  td {
    padding: .3rem .5em !important;
  }
}

.table-align-middle tr td {
  vertical-align: middle !important;
}

// TODO: move this style in common-ui
thgc-table-filters > .row {
  align-items: flex-end;
}

.p-radiobutton:not(.p-radiobutton-checked) .p-radiobutton-box {
  border: 1px solid #7b828b !important;
}

.option-label-tooltip {
  z-index: 10000 !important;
}

.cursor-pointer {
  cursor: pointer;
}

.h-full {
  height: 100%;
}

li:has(.invalid-po) {
  background-color: #d4516c !important;
}
